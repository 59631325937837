import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import Auth from "./components/Auth";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center"> Loading... </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));

window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.withCredentials = true;
//window.baseUrl = "https://xs591901.xsrv.jp/api/admin";
window.baseUrl = "https://xs591901.xsrv.jp/api/master";
// window.baseUrl = "http://tran-it.biz/foster/laravel/public/api/master";
// window.baseUrl = "http://localhost/api/master";

class App extends Component {
  state = {
    apiKey: "",
  };

  _setApiKey = (apiKey) => {
    this.setState({ apiKey: apiKey });
    window.localStorage.setItem("invoiceApiKey", apiKey);
  };

  componentWillMount = async () => {
    let apiKey = window.localStorage.getItem("invoiceApiKey");
    console.log("apiKey", apiKey);
    if (apiKey === null) apiKey = "";
    this.setState({ apiKey });
  };

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login"
              render={(props) => (
                <Login
                  apiKey={this.state.apiKey}
                  setApiKey={this._setApiKey}
                  {...props}
                />
              )}
            />{" "}
            <Auth apiKey={this.state.apiKey}>
              <Route
                path="/"
                name="Home"
                render={(props) => (
                  <DefaultLayout
                    apiKey={this.state.apiKey}
                    setApiKey={this._setApiKey}
                    {...props}
                  />
                )}
              />{" "}
            </Auth>{" "}
          </Switch>{" "}
        </React.Suspense>{" "}
      </HashRouter>
    );
  }
}

export default App;
